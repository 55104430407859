<template>
  <page title="退货/售后" >
    <div class=" top-tabs hairline-bottom relative" slot="fixed-top">
      <cube-scroll-nav-bar :current="current" :labels="labels" @change="changeHandler" class="bg-gray-50" />
    </div>
    <div class="tab-content" v-for="(itm, idx) in labels" :key="idx">
      <list v-if="itm == current">
        <media-item 
          :image="item.image" 
          :title="itm+item.title"  
          img-ratio="16/15"
          img-width="90"
          note="数量：202台"
          class="flex-1"
          v-for="(item,index) in TestData.examines" 
          :key="item.id" 
          :url="`/order-detail/${item.id}`"
        >
          <div class="text-gray-light mt-1 text-xs">
            订单编号：20202225522222
          </div>
          <div class="flex items-center justify-between" slot="bottom">
            <div class="mt-2 flex flex-wrap">
              <tag color="red" class="mr-2">1亿像素</tag>
              <tag color="purple" class="mr-2">双扬声器</tag>
              <tag color="green">线性马达</tag>
            </div>
            <btn color="orange-500" size="xs" text-size="xs" outline :url="`/apply-after/${index}`">申请售后</btn>
          </div>
        </media-item>
      </list>

    </div>

  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      current: '售后申请',
      labels: [
        '售后申请',
        '处理中',
        '申请记录',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    changeHandler(cur) {
      this.current = cur;
      console.log(cur);
    },
    goPay(){
      this.$router.push('/paid')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>